import { string } from 'prop-types';
import { useState } from 'react';
import { ChatButton } from '../chat-button';
import * as styles from './index.module.scss';
import { Spinner } from '../../spinner';

import sprites from './sprite.svg';
import { ChatScript } from '../../chat-script';
import { classNames } from '../../../helpers';
import { getLocalStorageItem } from '../../../helpers/misc';
import { ZoomCalendar } from '../../zoom-calendar';

function Icon({ id }) {
  return (
    <svg>
      <use
        xlinkHref={`${sprites}#${id}`}
      />
    </svg>
  );
}

function Contact({ state }) {
  const [open, setOpen] = useState(false);
  const phoneNumber = () => (
    <a href="tel:(303) 688-0944">303-688-0944</a>
  );

  const showPhoneNumber = () => (
    state ? phoneNumber(state) : phoneNumber(getLocalStorageItem('blogPostStateKey'))
  );

  const spinner = (
    <Spinner
      className={styles.center}
      spinnerHeight="50px"
      spinnerWidth="50px"
    />
  );
  return (
    <>
      {open && (
        <ZoomCalendar onClose={() => setOpen(false)} />
      )}
      <ChatScript />
      <div className={styles.content}>
        <div className={styles.grid}>
          <article className={classNames([styles.notShowMobile, styles.article])}>
            <div className={styles.call}>
              <Icon id="phone" />
              <div className={styles.callText}>Call</div>
              <div className={styles.callNumbers}>
                {showPhoneNumber()}
              </div>
            </div>
          </article>
          <a href="tel:(303) 688-0944" className={classNames([styles.onlyShowMobile, styles.article])}>
            <div className={styles.call}>
              <Icon id="phone" />
              <div className={styles.callText}>Call</div>
            </div>
          </a>
          <article className={styles.article}>
            <ChatButton
              className={styles.button}
              loadingComponent={spinner}
            >
              <Icon id="chat-2" />
              <h4 className={styles.title}>
                Chat
              </h4>
            </ChatButton>
          </article>
          <button
            type="button"
            onClick={() => setOpen(true)}
            className={styles.article}
          >
            <Icon id="schedule-alt" />
            <h4 className={styles.title}>
              Schedule
              <br />
              Online
            </h4>
          </button>
        </div>
      </div>
    </>
  );
}

Icon.propTypes = {
  id: string.isRequired,
};

Contact.propTypes = {
  state: string.isRequired,
};

export { Contact };

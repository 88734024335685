import { graphql, Link } from 'gatsby';
import { Wrap } from '../../components/sections-wrap';
import { Contact } from '../../components/common/contact';

import * as styles from './index.module.scss';

function FreeConsultation() {
  return (
    <div className={styles.pageContainer}>
      <Wrap>
        <main className={styles.dataContainer}>
          <article className={styles.entry}>

            <div className={styles.formContainer}>

              <div className={styles.formTitle}>

                <h2>Request an assessment</h2>
                <h3>Call (303) 688-0944 or request an assessment below</h3>
              </div>
              <Contact />
              <div className={styles.formDisclaimer}>
                <p>
                  If you choose to send an email to Robinson &amp; Henry,
                  P.C., or one of its lawyers, the sending or receipt of
                  the email and the information in it does not create an
                  attorney-client relationship between us. You also agree
                  that by sending an email you have read and agree to our
                  <Link to="/privacy/"> Privacy Policy</Link>
                  {' '}
                  and
                  <Link to="/policy-terms-conditions/"> Terms &amp; Conditions</Link>
                  .
                </p>
              </div>
            </div>
          </article>
        </main>
      </Wrap>
    </div>
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/free-consultation/"}) {
      ...SeoPage
    }
  }
`;

export default FreeConsultation;
export { Head } from '../../components/seo/seo';

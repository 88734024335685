// extracted by mini-css-extract-plugin
export var article = "index-module--article--06002";
export var blockLink = "index-module--block-link--1db16";
export var button = "index-module--button--45089";
export var buttonText = "index-module--button-text--0cd12";
export var dataContainer = "index-module--data-container--2a0f8";
export var entry = "index-module--entry--9e2de";
export var formContainer = "index-module--form-container--f9377";
export var formDisclaimer = "index-module--form-disclaimer--ac225";
export var formIcons = "index-module--form-icons--9e84e";
export var formTitle = "index-module--form-title--9054f";
export var pageContainer = "index-module--page-container--f78f3";
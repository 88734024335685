// extracted by mini-css-extract-plugin
export var article = "index-module--article--8e2cf";
export var blockLink = "index-module--blockLink--4d8a7";
export var button = "index-module--button--1d739";
export var call = "index-module--call--75bb7";
export var callNumbers = "index-module--callNumbers--9b15d";
export var callText = "index-module--callText--006de";
export var center = "index-module--center--3af38";
export var content = "index-module--content--fcf3d";
export var grid = "index-module--grid--b032a";
export var notShowMobile = "index-module--notShowMobile--13709";
export var onlyShowMobile = "index-module--onlyShowMobile--1dadc";
export var title = "index-module--title--284cb";